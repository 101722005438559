<template>
	<div class="city_databox" v-if="isShow">
        <p class="hot_city">
            <span>热门城市：</span>
            <i class=" icon_city_closecitybox_close" @click="isShow = false" />
        </p>
        <p class="hot_citylist">
            <a @click="chooseInputCity('北京')">北京</a>
            <a @click="chooseInputCity('上海')">上海</a>
            <a @click="chooseInputCity('广州')">广州</a>
            <a @click="chooseInputCity('深圳')">深圳</a>
            <a @click="chooseInputCity('杭州')">杭州</a>
            <a @click="chooseInputCity('重庆')">重庆</a>
            <a @click="chooseInputCity('天津')">天津</a>
            <a @click="chooseInputCity('南京')">南京</a>
            <a @click="chooseInputCity('苏州')">苏州</a>
        </p>
        <ul class="all_city_text">
            <li v-for="(item,zmIndex) in zmCityList" :key="zmIndex" :class="{active:upCityListIndex==zmIndex}" @click="upCityListKey(zmIndex)">{{item}}</li>
        </ul>
        <div class="all_city">
            <dl v-for="item,index in upCityList" :key="index">
                <dt>{{item.shortName}}</dt>
                <dd>
                    <a v-for="ritem,rIndex in item.cityList" :key="rIndex" @click="chooseInputCity(ritem.cityName)" :class="{choose:cityValue == ritem.cityName}">{{ritem.cityName}}</a>
                </dd>
            </dl>
        </div>
	</div>
</template>
<script>
import { getCityList}from "network/welfareMall_api.js";
export default {
    props:['isShow'],
	data(){
		return{
			cityValue: '',//input绑定的城市
			zmCityList: '',//字母列表
			upCityListIndex: 0,//选择城市的字母切换
			upCityList: '',//选择城市当前显示数据
			allCityList: '',//所有城市数据
		}
	},
	created(){
		this.getCityData();
	},
	methods:{
		//获取城市列表
		async getCityData(){
			let res = await getCityList();
			if(res.isSuccess){
				this.cityArr(res.data)
			}
		},
		//城市数据处理
		cityArr(list) {
			//获取所有城市并分组
			let chunk = 3;
			let result = [];
			for (var i = 0, j = list.length; i < j; i += chunk) {
				result.push(list.slice(i, i + chunk))
			}
			this.allCityList = result;//存储全部
			this.upCityList = result[0];//默认显示第一个
			//获取排列字母
			let cityListKey = [];
			result.map(item => {
				let shortNames = '';
				item.map(ritem => {
					shortNames += ritem.shortName;
				})
				cityListKey.push(shortNames);
			})
			this.zmCityList = cityListKey;
		},
		upCityListKey(index) {
			this.upCityListIndex = index;
			this.upCityList = this.allCityList[index];
		},
		chooseInputCity(city) {
            this.$emit('data-cityname',city);
		},
	}
}
</script>
<style lang="less" scoped>
.city_databox {
    width: 100%;
    height: auto;
    margin-top: 20px;
	.hot_city {
		display:flex;
		justify-content:space-between;
		margin-bottom:12px;
		span {
			font-size: 16px;
			font-weight: 400;
			color: #333333;
			line-height: 24px;
		}
		i{
			display:inline-block;
			cursor:pointer;
			width:18px;
			height:18px;
		}
	}
	.hot_citylist {
		display:flex;
		height:20px;
		a {   
			font-size: 12px;
			font-weight: 400;
			color: #333333;
			line-height: 20px;
			margin-right:16px;
			&:hover{
				cursor: pointer;
				color: #4c80fa;
			}
		}
	}
	.all_city_text {
		margin-top:12px;
		li{
			display: inline-block;
			padding: 5px 13px;
			font-size: 14px;
			font-weight: 400;
			color: #fff;
			background: #4C80FA;
			opacity: 0.5;
			&:hover{
				cursor: pointer;
			}
			&.active{
				cursor: pointer;
				opacity: 1;
			}
		}
	}
	.all_city dl {
		display:flex;
		margin-top:24px;
		&:first-child {
			margin-top:16px;
		}
		dt {
			display:inline-block;
			height:20px;
			line-height:20px;
			padding:0 7px;
			background: #4c80fa1a;
			font-size:14px;
			font-weight: 400;
			color: #4C80FA;
		}
		dd{
			display: inline-block;
			margin-left: 30px;
			a{
				display: inline-block;
				width: max-content;
				font-size:14px;
				font-weight: 400;
				color: #333333;
				line-height: 20px;
				margin-right: 20px;
				margin-bottom:5px;
				&:hover{
					cursor: pointer;
					color: #4C80FA;
				}
				&.choose{
					color: #4C80FA;
				}
			}
		}
	}
}
</style>