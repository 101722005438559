<template>
  <div class="meal_box">
    <search-com @search-btn="searchBtn"></search-com>
    <div class="nav_box">
      <welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
    </div>
    <div class="city_box">
      <div class="city_now" @click="openCheckCity">
        <img class="city_dw" src="~/assets/img/health/icon_dw.png" alt="定位图标"/>
        <span class="city_text">当前城市：<b>{{activeCity}}</b></span>
        <span class="city_btn"><i class="el-icon-sort"></i>切换城市</span>
      </div>
      <!-- 切换城市 -->
      <div class="city_list_box" v-if="showCityBox">
        <i class="el-icon-close" @click="showCityBox = false"></i>
        <el-input class="city_input" v-model="inputValue" placeholder="请输入城市名" clearable @input="searchInput()" @focus="searchFocus()"></el-input>
        <div class="result_box" v-if="inputValue&&inputValue.length">
          <div class="city_select_box" v-if="selectList&&selectList.length">
            <div class="select_result" v-for="selectItem in selectList" :key="selectItem.cityCode" @click="clickInput(selectItem.cityName)">{{selectItem.cityName}}</div>
          </div>
          <div class="city_select_none" v-else>未查询到相关内容</div>
        </div>
        <city-box :isShow="showCityBox" @data-cityname="getCityName"></city-box>
      </div>
    </div>
    <!-- 条件查询-->
    <div class="choose_box">
      <div class="choose_item">
        <span class="choose_text">价格区间</span>：
        <div class="list_box">
          <div class="list_item_box" v-for="priceItem in priceList" :key="priceItem.priceId" @click="selectOption('Price',priceItem.priceId)">
            <span :class="chooseList.Price==priceItem.priceId?'list_item choose_active':'list_item'">{{priceItem.priceText}}</span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">套餐类别</span>：
        <div class="list_box">
          <div class="list_item_box" @click="selectOption('MealType','')">
            <span :class="chooseList.MealType==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" v-show="taocanIndex<9||taocanShowMore" v-for="taocanItem,taocanIndex in taocanList" :key="taocanItem.typeId" @click="selectOption('MealType',taocanItem.typeId)">
            <span :class="chooseList.MealType==taocanItem.typeId?'list_item choose_active':'list_item'">{{taocanItem.typeName}}</span>
          </div>
          <div class="list_more" v-if="taocanList.length>9" @click="taocanShowMore = !taocanShowMore">
            <span>更多<i :class="taocanShowMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i></span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">医院等级</span>：
       <div class="list_box">
          <div class="list_item_box" @click="selectOption('HospRank','')">
            <span :class="chooseList.HospRank==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" v-for="dengjiItem,dengjiIndex in dengjiList" :key="dengjiIndex" @click="selectOption('HospRank',dengjiItem)">
            <span :class="chooseList.HospRank==dengjiItem?'list_item choose_active':'list_item'">{{dengjiItem}}</span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">适宜人群</span>：
         <div class="list_box">
          <div class="list_item_box" @click="selectOption('SuitablePopulation','')">
            <span :class="chooseList.SuitablePopulation==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" v-show="renqunIndex<9||renqunShowMore" v-for="renqunItem,renqunIndex in renqunList" :key="renqunItem.typeId" @click="selectOption('SuitablePopulation',renqunItem.typeId)">
            <span :class="chooseList.SuitablePopulation==renqunItem.typeId?'list_item choose_active':'list_item'">{{renqunItem.typeName}}</span>
          </div>
          <div class="list_more" v-if="renqunList.length>9" @click="renqunShowMore = !renqunShowMore">
            <span>更多<i :class="renqunShowMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i></span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">高发疾病</span>：
        <div class="list_box">
          <div class="list_item_box" @click="selectOption('ScreenName','')">
            <span :class="chooseList.ScreenName==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" v-show="renqunIndex<9||jibingShowMore" v-for="jibingItem,renqunIndex in jibingList" :key="jibingItem.typeId" @click="selectOption('ScreenName',jibingItem.typeId)">
            <span :class="chooseList.ScreenName==jibingItem.typeId?'list_item choose_active':'list_item'">{{jibingItem.typeName}}</span>
          </div>
          <div class="list_more" v-if="jibingList.length>9" @click="jibingShowMore = !jibingShowMore">
            <span>更多<i :class="jibingShowMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i></span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">年龄</span>：
        <div class="list_box">
          <div class="list_item_box" @click="selectOption('Age','')">
            <span :class="chooseList.Age==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" v-show="nianlingIndex<9||nianlingShowMore" v-for="nianlingItem,nianlingIndex in nianlingList" :key="nianlingItem.typeId" @click="selectOption('Age',nianlingItem.typeId)">
            <span :class="chooseList.Age==nianlingItem.typeId?'list_item choose_active':'list_item'">{{nianlingItem.typeName}}</span>
          </div>
          <div class="list_more" v-if="nianlingList.length>9" @click="nianlingShowMore = !nianlingShowMore">
            <span>更多<i :class="nianlingShowMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i></span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">机构地区</span>：
         <div class="list_box">
          <div class="list_item_box" @click="selectOption('AreaName','')">
            <span :class="chooseList.AreaName==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" v-show="areaIndex<9||areaShowMore" v-for="areaItem,areaIndex in areaList" :key="areaIndex" @click="selectOption('AreaName',areaItem.areaName)">
            <span :class="chooseList.AreaName==areaItem.areaName?'list_item choose_active':'list_item'">{{areaItem.areaName}}</span>
          </div>
          <div class="list_more" v-if="areaList.length>9" @click="areaShowMore = !areaShowMore">
            <span>更多<i :class="areaShowMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i></span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">机构服务</span>：
        <div class="list_box">
          <div class="list_item_box" @click="selectOption('HospService','')">
            <span :class="chooseList.HospService==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box" v-show="serviceIndex<9||serviceShowMore" v-for="serviceItem,serviceIndex in serviceList" :key="serviceIndex" @click="selectOption('HospService',serviceItem)">
            <span :class="chooseList.HospService==serviceItem?'list_item choose_active':'list_item'">{{serviceItem}}</span>
          </div>
          <div class="list_more" v-if="serviceList.length>9" @click="serviceShowMore = !serviceShowMore">
            <span>更多<i :class="serviceShowMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i></span>
          </div>
        </div>
      </div>
      <div class="choose_item">
        <span class="choose_text">推荐排序</span>：
        <div class="list_box">
          <div class="list_item_box" @click="selectOption('IsPriceOrderBy',false)">
            <span :class="chooseList.IsPriceOrderBy==''?'list_item choose_active':'list_item'">不限</span>
          </div>
          <div class="list_item_box"  @click="selectOption('IsPriceOrderBy',true)">
            <span :class="chooseList.IsPriceOrderBy==true?'list_item choose_active':'list_item'">价格优先</span>
          </div>
        </div>
      </div>
    </div>
    <div class="meal_list_box" v-if="mealData&&mealData.length">
      <div class="meal_list">
        <div class="meal_item" v-for="mealItem in mealData" :key="mealItem.mealId">
          <img :src="mealItem.mealImage" :alt="mealItem.mealName" @click="goMealDeatils(mealItem.mealId)"/>
          <p class="meal_title text_ellipsis" @click="goMealDeatils(mealItem.mealId)"><i class="meal_icon"></i>{{mealItem.mealName}}</p>
          <p class="meal_subtitle" @click="goMealDeatils(mealItem.mealId)">
            <span class="meal_yy text_ellipsis">{{mealItem.hospName}}</span>
            <span class="meal_dy">{{mealItem.areaName}}</span>
          </p>
          <p class="meal_other">
            <span class="meal_price" @click="goMealDeatils(mealItem.mealId)"><i>￥</i>{{mealItem.price}}</span>
            <span class="meal_btn" @click="addCarBtn(mealItem)">加入购物车</span>
          </p>
        </div>
      </div>
      <div class="meal_pagination">
        <el-pagination
          @current-change="OnCurrentPageChanged"
          @size-change="handleSizeChange"
          :current-page="Params.PageIndex"
          :page-sizes="[20, 40, 60, 80, 100]"
          layout="prev, pager,next,sizes,jumper,total"
          :page-size="Params.Limit"
          background
          :total="Params.TotalCount"
          >
        </el-pagination>
      </div>
    </div>
    <div class="meal_list_none" v-else>
      <img src="~/assets/img/health/none.png" alt="缺省图">
      <span>暂无内容</span>
    </div>
  </div>
</template>
<script>
import {mapState,mapMutations} from 'vuex';
import SearchCom from '@/components/welfareMallComp/SearchCom.vue';
import WelfareBreadcrumb from "@/components/welfareMallComp/WelfareBreadcrumb";
import cityBox from "@/components/common/city";
import {getCityList,getMealList,getDataType,getServiceList,getAreaList,AddHealthCart,GetCurrentCity } from "network/welfareMall_api.js";
export default {
  name: "TijianMealList",
  components: {
    SearchCom,
    WelfareBreadcrumb,
    cityBox,
  },
  data() {
    return {
      itemBreadcrumb: [
        {
          name: '福利商城',
          to: '/welfareHome'
        },
        {
          name: '员工体检',
        }
      ],
      showCityBox:false,
      activeCity:'',
      searchTimer:null,
      inputValue:'',//查询input
      selectList:[],//查询结果
      //选择中的数据
      chooseList:{
        CityName:'',
        Price:0,
        MealType:'',
        HospRank:'',
        SuitablePopulation:'',
        ScreenName:'',
        Age:'',
        AreaName:'',
        HospService:'',
        IsPriceOrderBy:false,
      },
      //数据列表
      priceList:[
        {
          priceId:0,
          priceText:'不限',
        },
        {
          priceId:1,
          priceText:'100以下',
        },
        {
          priceId:2,
          priceText:'100-300',
        },
        {
          priceId:3,
          priceText:'300-500',
        },
        {
          priceId:4,
          priceText:'500-800',
        },
        {
          priceId:5,
          priceText:'800-1500',
        },
        {
          priceId:6,
          priceText:'1500-3000',
        },
        {
          priceId:7,
          priceText:'3000以上',
        }
      ],
      dengjiList:[
        '公立三甲','公立医院','民营医院','专业体检中心',
      ],
      taocanList:[],
      jibingList:[],
      renqunList:[],
      nianlingList:[],
      areaList:[],
      serviceList:[],
      isPriceOrderBy:'',
      //数据 是否显示更多
      taocanShowMore:false,
      jibingShowMore:false,
      renqunShowMore:false,
      nianlingShowMore:false,
      areaShowMore:false,
      serviceShowMore:false,
      //套餐列表数据
      mealData:{},
      //分页
      Params:{
        PageIndex:1,
        Limit:20,
        TotalCount:0,
      }
    };
  },
  computed:{
    // 映射
    ...mapState(['useInfo'])
  },
  created(){
    //获取当前城市
    this.getCurrentCity();
   
  },
  methods:{
     // 映射mapMutations里的方法
    ...mapMutations(['saveCartNum']),
    async getCurrentCity(){
      let res = await GetCurrentCity();
      //console.log(res);
      if(res.isSuccess){
        this.activeCity = res.data;
        this.getAreaData(this.activeCity);
        //获取套餐列表
        this.getMealData();
        //获取选择数据
        this.getServiceData();
        this.getHealthChoose();
      }
    },
    // 点击查询 search
    searchBtn (val) {
      if(!val){
        return this.$message.info('请输入商品或礼包名称');
      }
      document.cookie = "vueRouter="+ '/welfareSearchResult';
      this.$router.push({
        name: 'WelfareSearchResult',
        params: {
          value: val
        }
      })
    },
    openCheckCity(){
      this.showCityBox=true;
      this.inputValue ='';
      this.chooseList.AreaName ='';
      this.selectList=[];
    },
    debounce(fn, delay = 100) {
      clearTimeout(this.searchTimer)
      this.searchTimer= setTimeout(fn, delay)
    },
    // 搜索输入框input事件
    searchInput() {
      if(this.inputValue&&this.inputValue.trim() !== '') {
        this.debounce(this.selectInput);
      }
    },
    // 搜索输入框focus事件
    searchFocus() {
      if(this.inputValue&&this.inputValue.trim() !== '') {
        this.debounce(this.selectInput);
      }
    },
    async selectInput(){
      let res = await getCityList(this.inputValue);
      if(res.isSuccess){
        let cityList = res.data;
        const outputData = cityList.reduce((acc, item) => {
          // 将当前项的 cityList 合并到累积器数组中
          return acc.concat(item.cityList.map(city => ({
            cityCode: city.cityCode,
            cityName: city.cityName
          })));
        }, []);
        this.selectList = outputData;
      }
    },
    clickInput(city){
      this.activeCity = city;
      this.getCityName(city);
      this.getMealData();
    },
    //根据城市获取区域
    getCityName(data){
      this.activeCity = data;
      this.getAreaData(data);
      this.getMealData();
      this.showCityBox=false;
    },
    //选择区域
    selectOption(type,value){
      this.chooseList[type] = value;
      this.Params.PageIndex = 1;
      this.getMealData();
    },
   
    //获取套餐列表
    async getMealData(){
      let data ={...this.chooseList,...this.Params};
      data.CityName = this.activeCity;
      delete data.TotalCount
      let res = await getMealList(data);
      if(res.isSuccess){
        this.mealData = res.data;
        this.Params.TotalCount = res.count;
        this.scrollToTop();
      }
    },
    //获取 员工体检 类型 1：套餐类别 2：高发疾病 3：适宜人群 4：年龄分组
    async getHealthChoose(){
      let taocanRes = await getDataType(1);
      let jibingRes = await getDataType(2);
      let renqunRes = await getDataType(3);
      let nianlingRes = await getDataType(4);
      if(taocanRes.isSuccess){
        this.taocanList = taocanRes.data;
      }
      if(jibingRes.isSuccess){
        this.jibingList = jibingRes.data;
      }
      if(renqunRes.isSuccess){
        this.renqunList = renqunRes.data;
      }
      if(nianlingRes.isSuccess){
        this.nianlingList = nianlingRes.data;
      }
    },
    //获取 员工体检 机构服务
    async getServiceData(){
      let res = await getServiceList();
      if(res.isSuccess){
        this.serviceList = res.data;
      }
    },
    //获取 员工体检 机构地区
    async getAreaData(city){
      let res = await getAreaList(city);
      if(res.isSuccess){
        this.areaList =res.data;
      }
    },
    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      this.getMealData();
    },
    handleSizeChange(val) {
      this.Params.Limit = val;
      this.getMealData();
    },
    // 跳转套餐详情页
    goMealDeatils(mealId){
      //console.log(mealId)
      this.$router.push({
        name: "TijianMealDetails",
        query: { mealId: mealId },
      });
    },
    //加入购物车
    async addCarBtn(mealItem){
      console.log(mealItem)
      let myCartList = [];
      let cartObj = {};
      cartObj.mealID = mealItem.mealId;
      cartObj.mealName = mealItem.mealName;
      cartObj.mealPrice = mealItem.price;
      cartObj.mealUrl = mealItem.mealImage;
      cartObj.mealCount = 1;
      cartObj.festivalName = '员工体检';
      cartObj.isActive = true;// 初始化当前套餐显示为有效
      cartObj.amount = mealItem.price * 1;//金额
      cartObj.orderType = 4;//类型

      // 判断是否已在缓存中添加有购物车
      if(sessionStorage.getItem('myCartList') && !this.useInfo.privateUserID){
        myCartList = JSON.parse(sessionStorage.getItem('myCartList'));
        // 此时是未登录状态添加进缓存
        // 判断是否有该礼包  无 则push缓存 反之
        var boo = myCartList.some((item)=>{
          return item.mealID === cartObj.mealID;
        })
        if(!boo){
          myCartList.push(cartObj);
        }else{
          // 循环判断缓存中是否有改同样礼包
          myCartList.forEach((item)=>{
            // 如果有 数量相加
            if(item.mealID === cartObj.mealID){
              item.mealCount += cartObj.mealCount;
              item.amount = (item.mealCount * item.mealPrice);
            }
          })
        }
        sessionStorage.setItem('myCartList', JSON.stringify(myCartList));
        this.saveCartNum(myCartList.length);
        this.$message.success('加入购物车成功');
        // 判断是否是登录状态
      }else if(!this.useInfo.privateUserID){
        myCartList.push(cartObj);
        sessionStorage.setItem('myCartList', JSON.stringify(myCartList));
        this.saveCartNum(myCartList.length);
        this.$message.success('加入购物车成功');
        // 已登录状态
      }else if(this.useInfo.privateUserID){
        const {mealID, mealCount} = cartObj;
        // 加入购物车调登录的加入接口
        var res = await AddHealthCart([{
          mealID,
          mealCount
        }]);
        if(res.isSuccess){
          this.$message.success(res.msg);
          // 在vuex中调用获取当前登录用户的购物车数量
          this.$store.dispatch('getLoginCartNum');
        }else{
          this.$message.error(res.msg);
        }
      }
    },
    //回到顶部
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>
<style lang="less" scoped>
.meal_box{
  width: 1200px;
  margin: 0 auto;
  .text_ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .city_box{
    margin-top: 30px;
    .city_now{
      cursor: pointer;
      display: flex;
      align-items: center;
      height:50px;
      padding-left: 15px;
      background: #F9FBFF;
      .city_dw{
        width: 15px;
        height: 18px;
        background-size: cover;
      }
      .city_text{
        margin-left: 8px;
        font-weight: bold;
        font-size: 14px;
        color: #333333;
        b{
          font-weight: bold;
          font-size: 17px;
          color: #FF9123;
        }
      }
      .city_btn{
        cursor: pointer;
        margin-left: 10px;        
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        border-left: 1px solid #999;
        i{
          margin-left: 10px;
          margin-right: 5px;
          transform: rotate(90deg);          
        }
      }
    }
    .city_list_box{
      margin:10px 0 0 15px;
      padding:10px 20px 20px;
      position: absolute;
      z-index: 1;
      width: 433px;
      background: #FFFFFF;
      border-radius: 12px;
      border: 1px solid #DCDCDC;
      .el-icon-close{
        position:absolute;
        top:10px;
        right: 10px;
        font-size: 16px;
      }
      .city_input{
        margin-top: 26px;
      }
      .result_box{
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #ECECEC;
        .city_select_box .select_result{
          font-size: 14px;
          color:#333;
          line-height: 26px;
          padding: 5px 20px;
          &:hover{
            cursor: pointer;
            color: #fff;
            background: #4C80FA;
          }
        }
        .city_select_none{
          font-size: 14px;
          color: #999;
          line-height: 26px;
          padding: 5px 20px;
        }
      }
    }
  }
  .choose_box{
    margin-top: 30px;
    .choose_item{
      display: flex;
      margin-bottom: 10px;
      .choose_text{
        display: inline-block;
        width: 56px;
        height: 26px;
        line-height: 26px;
        font-weight: normal;
        font-size: 14px;
        color: #333333;
        text-align: justify;
        text-align-last: justify;
      }
      .list_box{
        margin-left: 10px;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        .list_item_box{
          cursor: pointer;
          margin-bottom: 10px;
          flex:0 0 106px;
          display: inline-block;
          height: 26px;
          line-height: 24px;
        }
        .list_item{
          max-width: 104px;
          display: inline-block;
          font-size: 14px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:nth-child(1){
            margin-left: 0;
          }
        }
        .choose_active{
          padding:0 10px;
          font-size: 14px;
          color: #4C80FA;
          border:1px solid #4C80FA;
          border-radius: 5px;
        }
        .list_more{
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;          
          font-size: 14px;
          color: #333333;
         
        }
      }
    }
  }
  .meal_list_box{
    margin: 40px 0;
    .meal_list{
      display: flex;
      flex-wrap: wrap;
      .meal_item{
        margin: 0 20px 20px 0;
        padding-bottom: 20px;
        display: inline-flex;
        flex: 0 0 285px;
        border-radius: 10px;
        border: 1px solid #ededed;
        flex-direction: column;
        overflow: hidden;
        &:nth-child(4n+4){
          margin-right: 0;
        }
        img{
          cursor: pointer;
          width: 100%;
          height: 186px;
          background-size:cover;
          border-radius: 10px 10px 0px 0px;
        }
        .meal_title{
          cursor: pointer;
          margin-top: 20px;
          font-size: 14px;
          color: #333333;
          position:relative;
          padding-left: 24px;
          padding-right: 5px;
          .meal_icon{
            position: absolute;
            top: 3px;
            left: 0;
            width: 4px;
            height: 14px;
            background: #4C80FA;
            margin-right: 15px;
          }
        }
        .meal_subtitle{
          cursor: pointer;
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          padding:0 20px;
          font-size: 14px;
          color: #999999;
          .meal_yy{
            display: inline-block;
            width: 180px;
          }
        }
        .meal_other{
          margin-top: 17px;
          padding:0 20px;
          display: flex;
          justify-content: space-between;
          .meal_price{
            cursor: pointer;
            font-size: 22px;
            color: #EB3B3B;
            i{
              font-style: normal;
              font-size: 14px;
            }
          }
          .meal_btn{
            cursor: pointer;
            display: inline-block;
            padding: 5px 11px;
            font-size: 14px;
            color: #FFFFFF;
            background: #4C80FA;
            border-radius: 15px;
          }
        }
      }

    }
    .meal_pagination{
      margin-top: 20px;
      text-align: center;
    }
  }
  .meal_list_none{
    margin: 40px 0;
    text-align: center;
    img{
      display: block;
      margin: 0 auto;
      width: 270px;
      height: 270px;
      background-size: cover;
    }
    span{
      font-weight: 400;
      font-size: 18px;
      color: #999999;
      line-height: 60px;
    }
  }
}
</style>